export const PermissionEnum = {
	ADMIN__BRANDS__CRUD: "admin__brands__crud",
	ADMIN__BRANDS__READ: "admin__brands__read",

	ADMIN__CONTRACTORS__DELETE: "admin__contractors__delete",
	ADMIN__CONTRACTORS_ADDRESSES__DELETE: "admin__contractors_addresses__delete",

	ADMIN__COLLECTIONS__CRUD: "admin__collections__crud",
	ADMIN__COLLECTIONS__READ: "admin__collections__read",

	ADMIN__DISPLAYS__CRUD: "admin__displays__crud",
	ADMIN__DISPLAYS__READ: "admin__displays__read",
	ADMIN__DISPLAYS_ADDRESS_LOGS__READ: "admin__displays_address_logs__read",
	ADMIN__DISPLAYS_TYPE__CRUD: "admin__displays_type__crud",

	ADMIN__ORDERS__CREATE: "admin__orders__create",
	ADMIN__ORDERS__UPDATE: "admin__orders__update",
	ADMIN__ORDERS__DELETE: "admin__orders__delete",
	ADMIN__ORDERS__READ: "admin__orders__read",
	ADMIN__ORDERS__ACTION_GENERATE_PDF__PREVIEW: "admin__orders__action_generate_pdf__preview",
	ADMIN__ORDERS_ORDER_STATUS_LOGS__READ: "admin__orders_order_status_logs__read",

	ADMIN__PRODUCERS__CRUD: "admin__producers__crud",
	ADMIN__PRODUCERS__READ: "admin__producers__read",
	ADMIN__PRODUCERS__READ__FILTER: "admin__producers__read__filter",

	ADMIN__PRODUCTS__CRUD: "admin__products__crud",
	ADMIN__PRODUCTS__READ: "admin__products__read",

	ADMIN__ROLES__READ: "admin__roles__read",

	ADMIN__SELLERS__READ__FILTER: "admin__sellers__read__filter",

	ADMIN__SETTINGS__CRUD: "admin__settings__crud",

	ADMIN__USERS__READ: "admin__users__read",
	ADMIN__USERS__CRUD: "admin__users__crud",
	ADMIN__USER_LOGS__READ: "admin__user_logs__read",

	SHARED__DISPLAYS__READ: "shared__displays__read",
	SHARED__DISPLAYS_ADDRESS_LOGS__READ: "shared__displays_address_logs__read",

	SHARED__ANALYTICS_MAPS_CONTRACTOR_ADDRESS__READ: "shared__analytics_maps_contractor_address__read",

	SHARED__BRANDS__READ__FILTER: "shared__brands__read__filter",

	SHARED__COLLECTIONS__READ__FILTER: "shared__collections__read__filter",

	SHARED__CONTRACTORS__CREATE: "shared__contractors__create",
	SHARED__CONTRACTORS__UPDATE: "shared__contractors__update",
	SHARED__CONTRACTORS__READ: "shared__contractors__read",
	SHARED__CONTRACTORS__READ__FILTER: "shared__contractors__read__filter",
	SHARED__CONTRACTOR_ADDRESSES__READ__FILTER: "shared__contractor_addresses__read__filter",

	SHARED__DISPLAYS_TYPE__READ: "shared__displays_type__read",
	SHARED__DISPLAYS_TYPE__READ__FILTER: "shared__displays_type__read__filter",

	SHARED__DISTRIBUTORS__READ: "shared__distributors__read",
	SHARED__DISTRIBUTORS__READ__FILTER: "shared__distributors__read__filter",
	SHARED__DISTRIBUTORS__CRUD: "shared__distributors__crud",

	SHARED__ORDERS__CREATE: "shared__orders__create",
	SHARED__ORDERS__READ: "shared__orders__read",
	SHARED__ORDERS__READ__AVAILABLE_ACTIONS: "shared__orders__read__available_actions",
	SHARED__ORDERS__ACTION_EXPORT: "shared__orders__action_export",
	SHARED__ORDERS__ACTION_GENERATE_PDF: "shared__orders__action_generate_pdf",
	SHARED__ORDERS__ACTION_GENERATE_PDF__ACCEPTANCE_PROTOCOL: "shared__orders__action_generate_pdf__acceptance_protocol",
	SHARED__ORDERS_STATUS__UPDATE: "shared__orders_status__update",

	SHARED__PRODUCTS__READ__FILTER: "shared__products__read__filter",

	APP__ORDERS__READ__FILTER_BY_CONTRACTOR: "app__orders__read__filter_by_contractor",
	APP__ORDERS__READ__FILTER_BY_COLLECTION: "app__orders__read__filter_by_collection",
	APP__ORDERS__READ__FILTER_BY_PRODUCT: "app__orders__read__filter_by_product",
	APP__ORDERS__READ__FILTER_BY_DISTRIBUTOR: "app__orders__read__filter_by_distributor",
	APP__ORDERS__READ__CAN_SEE_COMMENT: "app__orders__read__can_see_comment",
	APP__ORDERS__READ__CAN_SEE_CONTRACTOR: "app__orders__read__can_see_contractor",
	APP__ORDERS__READ__CAN_SEE_DISTRIBUTOR: "app__orders__read__can_see_distributor",
	APP__ORDERS__READ__CAN_SEE_SELLER: "app__orders__read__can_see_seller",
	APP__ORDERS__READ__CAN_SEE_PRODUCER: "app__orders__read__can_see_producer",
	APP__ORDERS__READ__UPDATE_STATUS_CAN_SEE_NOTIFICATION: "app__orders__read__update_status_can_see_notification",
	APP__ORDERS__ACTION_CANCEL_REALISATION: "app__orders__action_cancel_realisation",
	APP__ORDERS__ACTION_REVERT_TO_REALISATION: "app__orders__action_revert_to_realisation",

	APP__ANALYTICS_MAPS__READ__FILTER_BY_SELLER: "app__analytics_maps__read__filter_by_seller",
	APP__ANALYTICS_MAPS__READ__FILTER_BY_PRODUCER: "app__analytics_maps__read__filter_by_producer",
	APP__ANALYTICS_MAPS__READ__FILTER_BY_DISTRIBUTOR: "app__analytics_maps__read__filter_by_distributor",
	APP__ANALYTICS_MAPS__READ__FOR_SELLER: "app__analytics_maps__read__for_seller",
	APP__ANALYTICS_MAPS__READ__FOR_PRODUCER: "app__analytics_maps__read__for_producer",
};
